/* Don't forget to add string 'products' to function combineReducers in './reducer.js' */
import { WP_API_BASE } from '../../shared/constants'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  data: [],
}

const LOAD_PRODUCTS_DATA = 'LOAD_PRODUCTS_DATA'
const LOAD_PRODUCTS_DATA_SUCCESS = 'LOAD_PRODUCTS_DATA_SUCCESS'
const LOAD_PRODUCTS_DATA_FAILURE = 'LOAD_PRODUCTS_DATA_FAILURE'

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOAD_PRODUCTS_DATA:
      return {
        ...state,
        isLoading: true,
      }

    case LOAD_PRODUCTS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: [...payload],
      }

    case LOAD_PRODUCTS_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      }

    default:
      return state
  }
}

export const loadProducts = searchRequest => dispatch => {
  if (searchRequest) {
    dispatch({ type: LOAD_PRODUCTS_DATA })

    // Rest API Params
    const params = {
      search: searchRequest,
    }

    const format = window.siteLang.format
    let apiBaseUrl = WP_API_BASE
    if (parseInt(format) === 3) {
      params.lang = window.siteLang.current
    } else if (window.siteLang.current !== window.siteLang.default) {
      apiBaseUrl = '/' + window.siteLang.current + apiBaseUrl
    }

    let url_params = '/'
    if (params) {
      let i = 0
      for (let key in params) {
        params.hasOwnProperty(key)
          ? (url_params += (i === 0 ? '?' : '&') + key + '=' + params[key])
          : false
        i++
      }
    }

    return fetch(`${apiBaseUrl}products${url_params}`, {
      method: 'GET',
    })
      .then(response => {
        if (response.ok && response.status === 200) {
          return response.json()
        }
        throw new Error(response.statusText)
      })
      .then(payload => {
        dispatch({
          payload,
          type: LOAD_PRODUCTS_DATA_SUCCESS,
        })

        return payload
      })
      .catch(error => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error) //eslint-disable-line
        }
        dispatch({
          error: error,
          type: LOAD_PRODUCTS_DATA_FAILURE,
        })
      })
  } else {
    dispatch({
      payload: [],
      type: LOAD_PRODUCTS_DATA_SUCCESS,
    })
  }
}
