import $ from 'jquery'

const dhsvVcHeroVideo = {
  init() {
    $(document).ready(() => {
      this.prevBreakpoint = this.viewport.current()
      this.changeVideo()
    })

    $(window).resize(() => {
      const currentBreakpoint = this.viewport.current()
      if (this.prevBreakpoint !== currentBreakpoint) {
        this.prevBreakpoint = currentBreakpoint
        this.changeVideo()
      }
    })
  },
  changeVideo() {
    $('.dhsv_vc_hero_video').each((key, el) => {
      const video = $(el).find('video')
      if (video.length) {
        const videoObject = video[0]
        let videoUrl = video.data('desktop')
        if (this.viewport.is('<md')) {
          videoUrl = video.data('mobile')
        }
        $('source', videoObject).attr('src', videoUrl)

        videoObject.load()
      }
    })
  },
  viewport: window.bootstrapToolkit,
  prevBreakpoint: '',
}
dhsvVcHeroVideo.init()
