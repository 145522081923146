import cssVars from 'css-vars-ponyfill'
import 'jquery-match-height'
import '@fancyapps/fancybox'

const dhsvCommon = {
  stickyBanner() {
    let scrollTop
    const body = $('body')
    const distance = 100

    function handleScroll() {
      scrollTop = $(window).scrollTop()

      if (scrollTop < distance) {
        body.removeClass('sticky-nav')
      } else {
        body.addClass('sticky-nav')
      }
    }

    handleScroll()
    $(window).on('scroll', this.throttled(10, handleScroll))
  },
  throttled(delay, fn) {
    let lastCall = 0
    return function(...args) {
      const now = new Date().getTime()
      if (now - lastCall < delay) {
        return
      }
      lastCall = now
      return fn(...args)
    }
  },
  setBodyWidth() {
    cssVars({
      variables: { '--body-width': document.body.clientWidth + 'px' },
    })
  },
  smoothScroll(link, e) {
    if (e) e.preventDefault()
    var hash = false
    if (!link && window.location.hash) {
      hash = window.location.hash
    } else if (link && link.indexOf('#') == 0) {
      hash = link
    } else if (
      link &&
      location.pathname.replace(/^\//, '') ==
        link.pathname.replace(/^\//, '') &&
      location.hostname == link.hostname
    ) {
      hash = link.hash
    }

    if (hash && $(hash).length) {
      $('html, body').animate(
        {
          scrollTop: $(hash).offset().top - 75,
        },
        600
      )
      history.replaceState(null, null, hash)
    }
    $(window).trigger('hashchange')
  },
  primaryMenuInit() {
    $('body').on('click', '.js-nav-primary-toggler', function() {
      $(this).toggleClass('active')
      $('.js-nav-primary')
        .fadeToggle(300)
        .toggleClass('is-open')
    })
  },
  contactPopup() {
    $('.contact-popup-open').on('click', function(e) {
      e.preventDefault()
      $('#contact-popup').fadeIn()
    })

    $('.contact-popup-close').on('click', function(e) {
      e.preventDefault()
      $('#contact-popup').fadeOut()
    })
  },
  contentToggle() {
    $('.faq__more').on('click', function(e) {
      e.preventDefault()
      $(this).toggleClass('open')
      $(this)
        .siblings('.faq__content')
        .slideToggle()
    })
  },
  stickyButton() {
    const scrolled = $(window).scrollTop()

    $('.btn-sticky').each(function() {
      const eTop = $(this)
        .closest('.dhsv_vc_button')
        .offset().top

      if (eTop <= scrolled + 45) {
        $(this)
          .closest('.dhsv_vc_button')
          .css({ height: $(this).innerHeight() })
        $(this).addClass('is-sticky')
      } else {
        $(this)
          .closest('.dhsv_vc_button')
          .css({ height: 'auto' })
        $(this).removeClass('is-sticky')
      }
    })
  },
  onInit() {
    this.setBodyWidth()
    this.stickyBanner()
    this.primaryMenuInit()
    this.contactPopup()
    this.contentToggle()
    this.stickyButton()

    if (window.location.hash) {
      this.smoothScroll(window.location.hash)
    }
  },
  onResize() {
    $(window).on('resize', () => {
      this.setBodyWidth()
    })
  },
  onScroll() {
    $(window).on('scroll', () => {
      this.stickyButton()
    })
  },
}

export default {
  init() {
    // JavaScript to be fired on all pages
    dhsvCommon.onInit()
    dhsvCommon.onResize()
    dhsvCommon.onScroll()

    $('body').on(
      'click',
      'a[href^="#"]:not([href="#"],[href="#0"],[href^="#fancy-"],[data-toggle],[role="tab"],[data-vc-accordion],[data-vc-tabs])',
      function(e) {
        dhsvCommon.smoothScroll($(this).attr('href'), e)
      }
    )

    // usercentrics settings modal
    $('a').on('click', function() {
      if (
        $(this)
          .attr('href')
          .includes('#uc-corner-modal-show')
      ) {
        window.UC_UI.showSecondLayer()
      }
    })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}
